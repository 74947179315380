import { backendTypes } from '.';
import { Observable } from 'rxjs';

export enum SYSTEM_SETTINGS {
  default_form_row_length = 2,
}

export interface DataType {
  id: number;
  name: string;
  description: string;
  metaData: string;
}

export type RefreshTimer = {
  type: string;
  refreshTimer: Observable<number>;
};

export type TableColumns = backendTypes.sqlEntity;

export type TimeColumns = backendTypes.sqlEntity;

export type Form = Entity;
export type Entity = backendTypes.sqlEntity;

export type Data = Entity[];

export type TimeReg = backendTypes.sqlTimeReg;
export type TimeForm = TimeReg;

export type TimeData = TimeReg[];

export type EntityFilterCondition = {
  key: 'entityId' | 'entityType' | 'clientId';
  value: any;
  operator?: 'equals' | 'contains';
};

export type EntityDataFilterCondition = {
  dataFieldId: number;
  data_value: string;
  operator?: 'equals' | 'contains';
};
