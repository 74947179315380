import {
  Component,
  inject,
  computed,
  Input,
  signal,
  OnInit,
  Signal,
  EventEmitter,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

import { CloseComponent } from '../buttons/close/close.component';
import { EditComponent } from '../buttons/edit/edit.component';

import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

import { DataFieldComponent } from '../data-field/data-field.component';

import { FunctionsService } from '../../../services/functions/functions.service';
import { SettingsService } from '../../../services/settings and config/settings.service';
import { frontendTypes } from '../../../assets/types';
import { backendTypes } from '../../../assets/types';
import { ConfigService } from '../../../services/settings and config/config.service';

@Component({
  selector: 'app-time',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    CommonModule,
    // ConfirmDialogComponent,
    MatIconModule,
    MatButtonModule,
    DataFieldComponent,
    MatCardModule,
  ],
  templateUrl: './registration.component.html',
  styleUrl: './registration.component.scss',
})
export class RegistrationComponent {
  functions = inject(FunctionsService);
  settings = inject(SettingsService);
  config = inject(ConfigService);

  SYSTEM_IDENTITIES = backendTypes.SYSTEM_IDENTITIES;

  _copyButton = signal(false);
  _editButton = signal(false);
  _removeButton = signal(false);
  _dataColumns = signal<frontendTypes.TableColumns | null>(null);
  _dataInput = signal<frontendTypes.TimeReg | null>(null);

  copyFunction = () => {
    console.log('no add function defined');
  };
  editFunction = (id: string) => {
    console.log('no edit function defined');
  };
  removeFunction = (id: string) => {
    console.log('no remove function defined');
  };

  @Input()
  set dataColumns(input: frontendTypes.TimeColumns | null) {
    if (input) {
      this._dataColumns.set(input);
    }
  }

  @Input()
  set dataInput(input: frontendTypes.TimeReg | null) {
    if (input) {
      this._dataInput.set(input);
    }
  }

  @Output() newTime = new EventEmitter<number>();

  emitNewTime(time: number) {
    this.newTime.emit(time);
  }
}
