<BR />

<div class="active-timereg-wrapper">
  <div class="active-timereg-functions-wrapper">
    <div>
      <button class="menu-button" mat-raised-button>Remove Rows</button>
      <button class="menu-button" mat-raised-button (click)="addRow()">
        Add Registration
      </button>
    </div>
    <button class="menu-button" mat-raised-button>Delete Registration</button>
  </div>

  <div class="registration-list">
    <div class="row">
      <mat-checkbox
        (change)="selectAll($event)"
        [checked]="isAllSelected()"
        [indeterminate]="!isAllSelected() && isAnySelected()"
        [disabled]="!activeTimeRegs || activeTimeRegs()?.length == 0"
      ></mat-checkbox>
      <div class="headers">
        <ng-container *ngFor="let col of headers; track: col">
          <div class="header">
            <span>{{ "SYSTEM_LABELS." + col | translate }}</span>
          </div>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="activeTimeRegs(); else noActiveTimeReg">
      <ng-container *ngFor="let T of activeTimeRegs(); track: T">
        <div class="row">
          <mat-checkbox
            (change)="singleSelect($event, T[0].timeId)"
            [checked]="selectedIDs.includes(T[0].timeId)"
          ></mat-checkbox>

          <app-time
            [dataColumns]="columns"
            [dataInput]="T"
            (newTime)="saveTime($event)"
          ></app-time>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #noActiveTimeReg>
      <span>No active registrations</span>
    </ng-template>
  </div>
</div>
