<mat-card appearance="outlined" class="mat-elevation-z4">
  <mat-card-content>
    <div class="registration">
      <ng-container *ngFor="let column of _dataColumns()">
        <app-data-field
          [fieldId]="column.dataFieldId.toString()"
          [data]="_dataInput()"
          [template]="'active-registration'"
          (newTime)="emitNewTime($event)"
        ></app-data-field>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
