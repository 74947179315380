<div class="timer">
  <div class="time" (click)="toggleInput()">
    <ng-container *ngIf="!manualInput">
      <span>{{ hr_display() + ": " }}</span>
      <span>{{ min_display() }}</span>
      <span *ngIf="isRunning"> {{ " :" + sec_display() }}</span></ng-container
    >
    <ng-container *ngIf="manualInput">
      <custom-time-input></custom-time-input
    ></ng-container>
  </div>
  <div>
    <button mat-icon-button (click)="toggle()">
      <mat-icon>{{ icon() }}</mat-icon>
    </button>

    <button mat-icon-button (click)="clear()">
      <mat-icon>replay</mat-icon>
    </button>
  </div>
</div>
