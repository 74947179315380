import {
  Component,
  inject,
  signal,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  OnChanges,
  computed,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { MatTableModule } from '@angular/material/table';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

import { ConfirmDialogComponent } from '../ui-components/confirm-dialog/confirm-dialog.component';
import { TimerComponent } from '../ui-components/timer/timer.component';
import { TimeRegService } from '../../services/dataservices/timereg.service';
import { ConfigService } from '../../services/settings and config/config.service';
import { FunctionsService } from '../../services/functions/functions.service';
import { SettingsService } from '../../services/settings and config/settings.service';

import { insertTimeRegForm, TimeColumns } from '../../assets/model/time.model';
import { AddTimeRegComponent } from './addtimereg/addtimereg.component';
import { DataFieldComponent } from '../ui-components/data-field/data-field.component';
import { RegistrationComponent } from '../ui-components/registration/registration.component';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-input-table',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TimerComponent,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatTableModule,
    CommonModule,
    MatCheckboxModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ConfirmDialogComponent,
    DataFieldComponent,
    RegistrationComponent,
    MatCardModule,
    TranslateModule,
  ],
  templateUrl: './timereg.component.html',
  styleUrl: './timereg.component.scss',
})
export class TimeregComponent {
  /** Injected services */

  dialog = inject(MatDialog);
  _formBuilder = inject(FormBuilder);
  timeReg = inject(TimeRegService);
  config = inject(ConfigService);
  translate = inject(TranslateService);

  settings = inject(SettingsService);
  functions = inject(FunctionsService);
  columns = TimeColumns;
  headers = this.columns.map(
    (col) => this.functions.getDataField(col.dataFieldId)?.label,
  );

  activeTimeRegs = computed(
    () =>
      this.timeReg.myTimeRegistrations()?.filter((t) => t[0].inActiveList) ||
      null,
  );
  selectedIDs: number[] = [];
  editForm = insertTimeRegForm;

  insertForm = insertTimeRegForm;

  editRow = () => {
    const dialogRef = this.dialog.open(AddTimeRegComponent, {
      data: {
        crudType: 'update',
        entity: null,
        form: this.editForm,
        formTitle: 'update timeregistration',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  };

  addRow = () => {
    const dialogRef = this.dialog.open(AddTimeRegComponent, {
      data: {
        crudType: 'insert',
        entity: null,
        form: this.insertForm,
        formTitle: 'insert time registration',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  };

  removeReg(id: string) {}

  saveTime(time: number) {
    console.log('time to save: ' + time);
  }

  // removeSelectedRows() {
  //   /** const registrations = this.dataSource.data.filter((r: Registration) => r.isSelected)  */
  //   this.dialog
  //     .open(ConfirmDialogComponent)
  //     .afterClosed()
  //     .subscribe((confirm: boolean) => {
  //       if (confirm) {
  //         console.log('call registration service, delete registrations');
  //         this.dataSource = this.dataSource.filter(
  //           (r: InputTableData) => !this.selectedIDs.includes(r.id),
  //         );
  //         this.selectedIDs = [];
  //         this.transformData(this.dataSource);
  //       }
  //     });
  // }

  isAllSelected() {
    if (!this.activeTimeRegs || !this.activeTimeRegs()) {
      return false;
    }
    let selected = this.selectedIDs.length > 0;
    this.activeTimeRegs!()!.forEach(
      (item) =>
        (selected = selected && this.selectedIDs.includes(item[0].timeId)),
    );
    return selected;
  }

  isAnySelected() {
    return this.selectedIDs.length > 0;
  }

  selectAll(event: any) {
    if (!this.activeTimeRegs || !this.activeTimeRegs()) {
      return;
    }
    this.selectedIDs = [];
    if (event.checked) {
      this.activeTimeRegs!()!.forEach((t) =>
        this.selectedIDs.push(t[0].timeId),
      );
    }
  }

  singleSelect(event: any, selection: number) {
    event.checked
      ? this.selectedIDs.push(selection)
      : (this.selectedIDs = this.selectedIDs.filter(function (id) {
          return id != selection;
        }));
  }

  constructor() {}
}
