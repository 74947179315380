<div
  role="group"
  class="custom-time-input-container"
  [formGroup]="parts"
  [attr.aria-labelledby]="_formField ? _formField.getLabelId() : null"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut($event)"
  (click)="onContainerClick()"
>
  <input
    class="custom-phone-input-element"
    formControlName="hour"
    size="2"
    maxLength="2"
    aria-label="Hours"
    (input)="_handleInput(parts.controls.hours, minutes)"
    #hours
    aria-autocomplete="none"
  />
  <span class="custom-phone-input-spacer">&ndash;</span>

  <input
    class="custom-phone-input-element"
    formControlName="minute"
    maxLength="2"
    size="2"
    aria-label="Minutes"
    (input)="_handleInput(parts.controls.minutes)"
    (keyup.backspace)="autoFocusPrev(parts.controls.minutes, hours)"
    #minutes
    aria-autocomplete="none"
  />
</div>
