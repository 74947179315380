import { FocusMonitor } from '@angular/cdk/a11y';
import { AsyncPipe, JsonPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  booleanAttribute,
  computed,
  effect,
  forwardRef,
  inject,
  input,
  model,
  signal,
  untracked,
  viewChild,
  Input,
  HostBinding,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormFieldControl,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Subject } from 'rxjs';

class Time {
  constructor(
    public hours: number,
    public minutes: number,
  ) {}
}

@Component({
  selector: 'custom-time-input',
  styleUrl: 'time.component.scss',
  templateUrl: 'time.component.html',
  host: {
    '[class.custom-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
  providers: [{ provide: MatFormFieldControl, useExisting: TimeInput }],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeInput
  implements ControlValueAccessor, MatFormFieldControl<number>, OnDestroy
{
  static nextId = 0;
  readonly hourInput = viewChild.required<HTMLInputElement>('country');
  readonly minuteInput = viewChild.required<HTMLInputElement>('subscriber');
  ngControl = inject(NgControl, { optional: true, self: true });
  readonly parts: FormGroup<{
    hours: FormControl<number | null>;
    minutes: FormControl<number | null>;
  }>;
  readonly stateChanges = new Subject<void>();
  readonly touched = signal(false);
  readonly controlType = 'custom-time-input';
  readonly id = `custom-time-input-${TimeInput.nextId++}`;
  readonly _userAriaDescribedBy = input<string>('', {
    alias: 'aria-describedby',
  });
  readonly _placeholder = input<string>('', { alias: 'placeholder' });
  readonly _required = input<boolean, unknown>(false, {
    alias: 'required',
    transform: booleanAttribute,
  });
  readonly _disabledByInput = input<boolean, unknown>(false, {
    alias: 'disabled',
    transform: booleanAttribute,
  });
  readonly _value = model<Time | null>(null, { alias: 'value' });
  onChange = (_: any) => {};
  onTouched = () => {};

  protected readonly _formField = inject(MAT_FORM_FIELD, {
    optional: true,
  });

  private readonly _focused = signal(false);
  private readonly _disabledByCva = signal(false);
  private readonly _disabled = computed(
    () => this._disabledByInput() || this._disabledByCva(),
  );
  private readonly _focusMonitor = inject(FocusMonitor);
  private readonly _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  get focused(): boolean {
    return this._focused();
  }

  get empty() {
    const {
      value: { hours, minutes },
    } = this.parts;

    return !hours && !minutes;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get userAriaDescribedBy() {
    return this._userAriaDescribedBy();
  }

  get placeholder(): string {
    return this._placeholder();
  }

  get required(): boolean {
    return this._required();
  }

  get disabled(): boolean {
    return this._disabled();
  }

  get value(): number | null {
    if (!this._value() || (!this._value()?.minutes && !this._value()?.hours)) {
      return null;
    }

    const hourSecs = this._value()?.hours
      ? 3600 * Number(this._value()!.hours)
      : 0;
    const minuteSecs = this._value()?.minutes
      ? 60 * Number(this._value()!.minutes)
      : 0;
    const time = hourSecs + minuteSecs;
    return time;
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched();
  }
  constructor() {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.parts = inject(FormBuilder).group({
      hours: [0, [Validators.maxLength(2), Validators.pattern('^[0-9]*$')]],

      minutes: [0, [Validators.maxLength(2), Validators.pattern('^[0-9]*$')]],
    });

    effect(() => {
      // Read signals to trigger effect.
      this._placeholder();
      this._required();
      this._disabled();
      this._focused();
      // Propagate state changes.
      untracked(() => this.stateChanges.next());
    });

    effect(() => {
      if (this._disabled()) {
        untracked(() => this.parts.disable());
      } else {
        untracked(() => this.parts.enable());
      }
    });

    effect(() => {
      const value = this._value() || new Time(0, 0);
      untracked(() => this.parts.setValue(value));
    });

    this.parts.statusChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      this.stateChanges.next();
    });

    this.parts.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      const time = this.parts.valid
        ? new Time(this.parts.value.hours || 0, this.parts.value.minutes || 0)
        : null;
      this._updateValue(time);
    });
  }

  getMaxLength(control: AbstractControl): number | null {
    if (control && control.validator) {
      const validator = control.validator({} as any);

      if (validator && validator['maxlength']) {
        return validator['maxlength'].requiredLength;
      }
    }
    return null;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn() {
    if (!this._focused()) {
      this._focused.set(true);
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched.set(true);
      this._focused.set(false);
      this.onTouched();
    }
  }

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement,
  ): void {
    if (!control.value) {
      return;
    }
    if (
      !control.errors &&
      nextElement &&
      control.value?.length === this.getMaxLength(control)
    ) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (!control.value) {
      return;
    }
    if (control.value?.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.custom-phone-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    this._focusMonitor.focusVia(this.hourInput(), 'program');
  }

  writeValue(time: Time | number | null): void {
    // If `time` is a number, convert it to a `Time` instance
    if (typeof time === 'number') {
      const hours = Math.trunc(time / 3600);
      const minutes = Math.trunc((time - hours * 3600) / 60);

      time = new Time(hours, minutes);
    }

    if (time instanceof Time && !(time.hours == null && time.minutes == null)) {
      this.parts.setValue(
        { hours: time.hours, minutes: time.minutes },
        { emitEvent: false },
      );
      this._updateValue(time);
    } else {
      this.parts.reset();
      this._updateValue(null);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabledByCva.set(isDisabled);
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }

  private _updateValue(time: Time | null) {
    const current = this._value();
    if (
      time === current ||
      (time?.hours === current?.hours && time?.minutes === current?.minutes)
    ) {
      return;
    }
    this._value.set(time);
  }
}
