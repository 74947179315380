import {
  Component,
  signal,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TimeInput } from '../input/time/time.component';

@Component({
  selector: 'app-timer',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    CommonModule,
    forwardRef(() => [TimeInput]),
  ],
  templateUrl: './timer.component.html',
  styleUrl: './timer.component.scss',
})
export class TimerComponent {
  _startTime = 0;

  hr = 0;
  min = 0;
  sec = 0;

  @Input()
  set savedTime(input: number | null) {
    if (input) {
      this._startTime = input;
      this.hr = Math.trunc(this._startTime / 3600);
      this.min = Math.trunc((this._startTime - 3600 * this.hr) / 60);
      this.sec = this._startTime - 3600 * this.hr - 60 * this.min;

      this.hr_display.set(
        this.hr >= 10 ? this.hr.toString() : '0' + this.hr.toString(),
      );
      this.min_display.set(
        this.min >= 10 ? this.min.toString() : '0' + this.min.toString(),
      );
      this.sec_display.set(
        this.sec >= 10 ? this.sec.toString() : '0' + this.sec.toString(),
      );
    }
  }

  @Output() valueChange = new EventEmitter<number>();

  manualInput = false;

  startTimer: any;

  hr_display = signal('00');
  min_display = signal('00');
  sec_display = signal('00');

  isRunning = false;
  icon = signal('play_circle_outline');

  public toggle() {
    this.isRunning = !this.isRunning;
    !this.isRunning
      ? this.icon.set('play_circle_outline')
      : this.icon.set('pause_circle_outline');
    if (this.isRunning) {
      this.start();
      return;
    }
    this.stop();
  }

  toggleInput() {
    this.manualInput = !this.manualInput;
  }

  clear() {
    clearInterval(this.startTimer);
    const hr = Math.trunc(this._startTime / 3600);
    const min = Math.trunc((this._startTime - 3600 * hr) / 60);
    const sec = this._startTime - 3600 * hr - 60 * min;

    this.hr_display.set('00');
    this.min_display.set('00');
    this.sec_display.set('00');
    this.isRunning = false;
    this.icon.set('play_circle_outline');
  }

  start() {
    this.startTimer = setInterval(() => {
      this.sec++;
      if (this.sec === 60) {
        this.min++;
        this.sec = 0;
        if (this.min === 60) {
          this.hr++;
          this.min = 0;
          this.hr_display.set(
            this.hr >= 10 ? this.hr.toString() : '0' + this.hr.toString(),
          );
        }
        this.min_display.set(
          this.min >= 10 ? this.min.toString() : '0' + this.min.toString(),
        );
      }

      this.sec_display.set(
        this.sec >= 10 ? this.sec.toString() : '0' + this.sec.toString(),
      );
    }, 1000);
  }

  stop() {
    clearInterval(this.startTimer);
    this.valueChange.emit(this.hr * 3600 + this.min * 60 + this.sec);
  }
}
