import { frontendTypes } from '../types';

export const insertTimeRegForm: frontendTypes.TimeForm = [
  {
    dataFieldId: 81,
    data_value:
      '{"row": 1, "position": 1, "required": true, "disabled": false}',
    id: 0,
    timeId: 3,
    clientId: 1,
    userId: 1,
    createdDate: '',
    createdBy: 1,
    editedDate: '',
    editedBy: 1,
    inActiveList: false,
    regStatus: 'open',
  },

  {
    dataFieldId: 84,
    data_value:
      '{"row": 1, "position": 2, "required": true, "disabled": false}',
    id: 0,
    timeId: 3,
    clientId: 1,
    userId: 1,
    createdDate: '',
    createdBy: 1,
    editedDate: '',
    editedBy: 1,
    inActiveList: false,
    regStatus: 'open',
  },
  {
    dataFieldId: 82,
    data_value:
      '{"row": 2, "position": 1, "required": false, "disabled": false}',
    id: 0,
    timeId: 3,
    clientId: 1,
    userId: 1,
    createdDate: '',
    createdBy: 1,
    editedDate: '',
    editedBy: 1,
    inActiveList: false,
    regStatus: 'open',
  },
  {
    dataFieldId: 85,
    data_value:
      '{"row": 2, "position": 2, "required": false, "disabled": false}',
    id: 0,
    timeId: 3,
    clientId: 1,
    userId: 1,
    createdDate: '',
    createdBy: 1,
    editedDate: '',
    editedBy: 1,
    inActiveList: false,
    regStatus: 'open',
  },
];

// 81	timereg_project
// 82	timereg_notes
// 83	timereg_id
// 84	timereg_date
// 85	timereg_hours

export const TimeColumns: frontendTypes.TimeColumns = [
  {
    dataFieldId: 81,
    data_value:
      '{"row": 1, "position": 1, "required": true, "disabled": false}',
    id: 0,
    entityId: 1,
    entityType: 'time-columns',

    clientId: 1,
  },

  {
    dataFieldId: 82,
    data_value:
      '{"row": 1, "position": 1, "required": true, "disabled": false}',
    id: 0,
    entityId: 1,
    entityType: 'time-columns',

    clientId: 1,
  },
  {
    dataFieldId: 83,
    data_value:
      '{"row": 1, "position": 2, "required": true, "disabled": false}',
    id: 0,
    entityId: 1,
    entityType: 'time-columns',

    clientId: 1,
  },
  {
    dataFieldId: 84,
    data_value:
      '{"row": 2, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityId: 1,
    entityType: 'time-columns',

    clientId: 1,
  },
  {
    dataFieldId: 85,
    data_value:
      '{"row": 3, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityId: 1,
    entityType: 'time-columns',

    clientId: 1,
  },
];
